<!-- src/App.vue -->
<template>
  <div id="app">
    <ParticleBackground />
    <router-view></router-view>
  </div>
</template>

<script>
import ParticleBackground from './components/ParticleBackground.vue';

export default {
  name: 'App',
  components: {
    ParticleBackground,
  },
};
</script>

<style>
body {
  background-color: #f0f0f0; /* 设置背景颜色为淡灰色 */
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#app {
  text-align: center;
  color: #2c3e50;
}

a {
  color: #42b983;
}
</style>