// src/router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import HomePage from '../views/HomePage.vue';
import SingleSequence from '../views/SingleSequence.vue';
import UploadFasta from '../views/UploadFasta.vue';

const routes = [
  { path: '/', name: 'HomePage', component: HomePage },
  { path: '/single-sequence', name: 'SingleSequence', component: SingleSequence },
  { path: '/upload-fasta', name: 'UploadFasta', component: UploadFasta },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;