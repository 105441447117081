<!-- src/components/ParticleBackground.vue -->
<template>
  <div id="particles-js"></div>
</template>

<script>
import 'particles.js/particles';
const particlesJS = window.particlesJS;
export default {
  name: 'ParticleBackground',
  mounted() {
    require('particles.js');
    particlesJS.load('particles-js', '/particles-config.json', function() {
      console.log('particles.js loaded - callback');
    });
  },
};
</script>

<style scoped>
#particles-js {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  background-color: rgb(214, 214, 214); /* 设置背景颜色为黑色 */
}
</style>