<!-- src/views/UploadFasta.vue -->
<template>
  <div class="upload-fasta">
    <div class="header-links">
      <router-link to="/" class="link-button">Home</router-link>
      <router-link to="/single-sequence" class="link-button">Single Amino Acid Sequence Prediction</router-link>
    </div>
    <h2>Upload FASTA File Prediction</h2>
    <p class="instructions">
      Please upload a FASTA file. The file size should not exceed 1MB.
    </p>
    <div class="example">
      <p>Example of a valid FASTA file:</p>
      <pre>
&gt;protein1
MKTIIALSYIFCLVFADYKDDDDK
&gt;protein2
MTVKIYALSYIFCLVFADYKDDDDK
      </pre>
      <div class="notice">
        Note: The FASTA file should have each protein name starting with '>' on a new line, followed by the amino acid sequence on the next line. The amino acid sequence should not be split across multiple lines.
      </div>
    </div>
    <form @submit.prevent="submitFile">
      <div class="form-group">
        <label for="file" class="file-label">Upload FASTA File:</label>
        <input type="file" id="file" @change="handleFileUpload" accept=".txt,.fasta" required />
      </div>
      <button type="submit" class="submit-button">Submit</button>
    </form>
    <div v-if="errorMessage" class="error-message">{{ errorMessage }}</div>
  </div>
</template>

<script>
export default {
  name: 'UploadFasta',
  data() {
    return {
      file: null,
      errorMessage: '',
    };
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
      this.errorMessage = '';

      // Check file size
      if (this.file.size > 1048576) { // 1MB in bytes
        this.errorMessage = 'File size should not exceed 1MB.';
        this.file = null;
        return;
      }

      // Check file format
      const reader = new FileReader();
      reader.onload = (e) => {
        const content = e.target.result;
        if (!this.isValidFasta(content)) {
          this.errorMessage = 'Invalid FASTA format or contains invalid characters.';
          this.file = null;
        }
      };
      reader.readAsText(this.file);
    },
    isValidFasta(content) {
      const lines = content.split('\n');
      for (let i = 0; i < lines.length; i += 2) {
        if (!lines[i].startsWith('>')&&lines[i].length>0) {
          return false;
        }
      }
      return true;
    },
    async submitFile() {
      if (!this.file) {
        alert('Please upload a valid file.');
        return;
      }

      const formData = new FormData();
      formData.append('file', this.file);

      try {
        const response = await fetch('http://func.frppredict.site:5000/upload-fasta', {
          method: 'POST',
          body: formData,
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'predictions.csv';
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
      } catch (error) {
        console.error('Error:', error);
        alert('PLEASE CHECK YOU ARE USING HTTP INSTEAD OF HTTPS!!!');
      }
    },
  },
};
</script>

<style scoped>
.upload-fasta {
  text-align: center;
  padding: 20px;
  background-size: cover;
  background-color: #ffd6d6;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 50px auto;
  color: #fff;
}

.upload-fasta h2 {
  color: #026958;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.instructions {
  color: #333;
  font-size: 1em;
  margin-bottom: 20px;
}

.example {
  color: #333;
  font-size: 1em;
  margin-bottom: 20px;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 5px;
}

.notice {
  color: #e05d5d;
  font-size: 0.9em;
  margin-top: 10px;
}

.header-links {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

h2 {
  color: #fff;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.file-label {
  display: block;
  color: #ddd;
  font-size: 1.2em;
  margin-bottom: 10px;
}

input[type="file"] {
  width: 80%;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 5px;
  border: 4px solid #ccc;
}

.submit-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.link-button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #0056b3;
}

.file-label {
  color: #526b08;
  font-size: 1.5em;
}

.error-message {
  color: red;
  font-size: 1.2em;
  margin-top: 20px;
}
</style>