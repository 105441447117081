<!-- src/views/SingleSequence.vue -->
<template>
  <div class="single-sequence">
    <div class="header-links">
      <router-link to="/" class="link-button">Home</router-link>
      <router-link to="/upload-fasta" class="link-button">Upload FASTA File</router-link>
    </div>
    <h2>Single Amino Acid Sequence Prediction</h2>
    <form @submit.prevent="submitSequence">
      <div class="form-group">
        <label for="sequence" class="sequence-label">Enter Amino Acid Sequence:</label>
        <input type="text" id="sequence" v-model="sequence" required />
      </div>
      <button type="submit" class="submit-button">Submit</button>
    </form>
    <div v-if="predictionResult !== null" class="result">
      <h3>Prediction Result: {{ displayPrediction }}</h3>
    </div>
    <div v-if="errorMessage" class="error">
      <h3>Error: {{ errorMessage }}</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SingleSequence',
  data() {
    return {
      sequence: '',
      predictionResult: null, // 用于存储预测结果
      errorMessage: '', // 用于存储错误信息
    };
  },
  computed: {
    displayPrediction() {
      if (typeof this.predictionResult === 'boolean') {
        return this.predictionResult ? 'Yes' : 'No';
      }
      return this.predictionResult;
    },
  },
  methods: {
    isValidSequence(sequence) {
      const validAminoAcids = /^[ACDEFGHIKLMNPQRSTVWY]+$/i;
      return validAminoAcids.test(sequence);
    },
    async submitSequence() {
      // 重置以前的结果和错误信息
      this.predictionResult = null;
      this.errorMessage = '';

      if (!this.isValidSequence(this.sequence)) {
        this.errorMessage = 'Invalid amino acid sequence. Please enter a valid sequence.';
        return;
      }

      try {
        const response = await fetch('http://func.frppredict.site:5000/predict', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sequence: this.sequence }),
        });

        console.log('Response status:', response.status);
        const responseText = await response.text();
        console.log('Response text:', responseText);

        // 尝试解析 JSON
        const result = JSON.parse(responseText);
        console.log('Parsed result:', result);

        if (!response.ok) {
          throw new Error(result.message || 'Network response was not ok');
        }

        this.predictionResult = result.prediction; // 将预测结果存储到 predictionResult 中
      } catch (error) {
        console.error('Error:', error+"PLEASE CHECK YOU ARE USING HTTP INSTEAD OF HTTPS!!!");
        this.errorMessage = error.message+" \n PLEASE CHECK YOU ARE USING HTTP INSTEAD OF HTTPS!!!" || 'An error occurred while processing your request.';
      }
    },
  },
};
</script>

<style scoped>
.single-sequence {
  text-align: center;
  padding: 20px;
  background-size: cover;
  border-radius: 10px;
  background-color: #ffcaca;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 800px;
  margin: 50px auto;
  color: #000;
}

.single-sequence h2 {
  color: #026958;
  font-size: 2.5em;
  margin-bottom: 20px;
}

.header-links {
  display: flex;
  justify-content: flex-end;
  gap: 20px;
  margin-bottom: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.sequence-label {
  display: block;
  color: #526b08; /* 修改此处为你想要的颜色 */
  font-size: 1.5em;
  margin-bottom: 10px;
}

input[type="text"] {
  width: 80%;
  padding: 10px;
  font-size: 1.2em;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.submit-button {
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  border: none;
  font-size: 1.2em;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0056b3;
}

.link-button {
  display: inline-block;
  padding: 10px 20px;
  color: #fff;
  background-color: #007bff;
  border-radius: 5px;
  text-decoration: none;
  transition: background-color 0.3s ease;
}

.link-button:hover {
  background-color: #0056b3;
}

.result {
  margin-top: 20px;
  color: #000000;
  font-size: 1.5em;
}

.result h3 {
  color: #ff6347;
}

.error {
  margin-top: 20px;
  color: red;
  font-size: 1.2em;
}
</style>